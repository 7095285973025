import { v4 as uuidv4 } from 'uuid';

export default {
  namespaced: true,
  state: {
    VOTE_STORAGE_NAME: 'VANLLAGE_VOTE',
    voteToken: uuidv4(),
    votes: [
      // {
      //   serial: -1,
      // }
    ],
  },
  getters: {
    voteList(state) {
      return state.votes;
    },
    voteCoda(state) {
      return 5 - state.votes.length;
    },
  },
  mutations: {
    ADDVOTE(state, serial) {
      state.votes.push({
        serial,
      });
    },
  },
  actions: {
    readVoteFromStorage(context) {
      const saved = window.localStorage.getItem(context.state.VOTE_STORAGE_NAME);
      if (saved !== undefined) {
        const obj = JSON.parse(saved);
        if (obj !== null) {
          context.state.voteToken = obj.voteToken;
          for (const item of obj.votes) {
            context.commit('ADDVOTE', item.serial);
          }
        }
      }
    },
    saveVoteToStorage(context) {
      window.localStorage.setItem(context.state.VOTE_STORAGE_NAME, JSON.stringify({
        voteToken: context.state.voteToken,
        votes: context.state.votes
      }));
    },
    async voteTo(context, serial) {
      if (context.getters.voteCoda > 0) {
        await context.dispatch('api/postVotePromise', {
          userToken: context.state.voteToken,
          voteSerial: serial
        }, {root: true});
        context.commit('ADDVOTE', serial);
        context.dispatch('saveVoteToStorage');
        return true;
      }
      return false;
    },    
  }

}
