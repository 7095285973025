import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/map',
    // component: () => import(/* webpackChunkName: "homeView" */ '../views/HomeView.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "MapView" */ '../views/MapView.vue')
  },
  {
    path: '/list',
    name: 'CampListView',
    component: () => import(/* webpackChunkName: "CampListView" */ '../views/CampListView.vue')
  },
  {
    path: '/votedList',
    name: 'VotedListView',
    component: () => import(/* webpackChunkName: "VotedListView" */ '../views/VotedListView.vue')
  },
  {
    path: '/sponsor',
    name: 'SponsorListView',
    component: () => import(/* webpackChunkName: "SponsorListView" */ '../views/SponsorListView.vue')
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
  },
  {
    path: '/*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(async(to) => {
  // console.log(`${JSON.stringify(to.meta)}`);
  let hideNav = false;
  if (to.meta.hideNav) {
    hideNav = true;
  }
  store.commit('SETNAVHIDE', hideNav);
});

export default router
