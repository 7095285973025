<template>
  <div id="app" v-if="init">
    
    <div class="content">
      <router-view/>
    </div>

    <nav>
      <ul>
        <li>
          <router-link :to="{name: 'Map'}">
            <img :src="require('@/assets/img/nav/map.png')" alt="">
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'CampListView'}">
            <img :src="require('@/assets/img/nav/list.png')" alt="">
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'VotedListView'}">
            <img :src="require('@/assets/img/nav/voted.png')" alt="">
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'SponsorListView'}">
            <img :src="require('@/assets/img/nav/sponsor.png')" alt="">
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'LoginView'}">
            <img :src="require('@/assets/img/nav/login.png')" alt="">
          </router-link>
        </li>
      </ul>
    </nav>

    <Transition name="fade">
      <div class="loading-mask" v-if="isStoreBusy">
        <b-spinner></b-spinner>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import moment from 'moment';
moment.locale('zh-tw');

export default {
  name: 'AppView',
  metaInfo() {
    return {
      title: 'VANLLAGE VOTE',
    };
  },  
  data() {
    return {
      init: false,
    };
  },
  async mounted() {
    await this.$store.dispatch('car/refreshCarList');
    this.$store.dispatch('vote/readVoteFromStorage');
    this.$store.dispatch('vote/saveVoteToStorage');
    this.init = true;

  },
  created() {
    
  },
  destroyed() {
  },
  components: {
    
  },
  watch: {
    $route(to) {
      if (to.meta.noToTop === undefined || !to.meta.noToTop) {
        window.scrollTo(0,0);
      }
      // console.log(to);
    }
  },
  computed: {
    ...mapGetters(['isStoreBusy', 'auth/hasToken']),
    ...mapState(['hideNav']),
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    ...mapActions('cart', ['readCartFromStorage']),
    resizeHandler() {
      this.$store.commit('SETWINDOWWIDTH', window.innerWidth);
    },
  }
}
</script>

<style>
html {

}

.sub-page {
  padding-top: 100px;
}
</style>

<style lang="scss" scoped>

.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0005;
  opacity: 1;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}


</style>

<style lang="scss">
#app {
  color: #333333;
  
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 900;
    ul {
      padding: 1rem .5rem;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      column-gap: 1rem;
      li {
        padding: .5rem;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>