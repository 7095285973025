export default {
  namespaced: true,
  state: {
    cars: [],
  },
  getters: {
    carList(state) {
      return state.cars;
    },
  },
  mutations: {
    setCars(state, data) {
      state.cars = data;
    },
  },
  actions: {
    async refreshCarList({ commit, dispatch }) {
      const busyName = 'refreshCarList';
      await dispatch('appendComponentBusy', busyName, { root: true });
      try {
        const res = await dispatch('api/getCarListPromise', null, { root: true });
        commit('setCars', res);

      } catch (error) {
        await dispatch('appendErrorMsg', error.toString(), { root: true });
        await dispatch('showMsgModal', error.toString(), { root: true });
      } finally {
        await dispatch('clearComponentBusy', busyName, { root: true });
      }
    },
  }

}
